import * as api from "@/api";
import {
  carIdling,
  carMoving,
  carMovingEast,
  carMovingNorth,
  carMovingSouth,
  carMovingWest,
  carNoSignal,
  carStopped,
  getIconHeading,
} from "@/assets/carIcons";
import ErrorAlert from "@/components/ErrorAlert";
import HereMap from "@/components/HereMap";
import useIsPhone from "@/hooks/useIsPhone";
import { makeVehicleInfoBubbleHtml } from "@/mapFns";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js";
import { ActionIcon, Group, LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { FaTags } from "react-icons/fa";
import { MdFilterAlt } from "react-icons/md";
import VehicleFilterPanel from "./VehicleFilterPanel";

const getStatus = (v) => {
  let status = "Unknown",
    statusDate = null,
    lastIdleTime = v.lastIdlePoint?.triggerTime,
    lastIgnitionTime = v.lastIgnitionPoint?.triggerTime;

  if (v.lastIdlePoint != null && (lastIdleTime > lastIgnitionTime || lastIgnitionTime == null)) {
    status = "Idling";
    statusDate = v.lastIdlePoint.triggerTime;
  } else if (v.lastIgnitionPoint?.eventName == "IgnitionOn") {
    status = "Moving";
    statusDate = v.lastIgnitionPoint.triggerTime;
  } else if (v.lastIgnitionPoint?.eventName == "IgnitionOff") {
    status = "Stopped";
    statusDate = v.lastIgnitionPoint.triggerTime;
  }
  return { status, statusDate };
};

const iconOptions = { anchor: { x: 16, y: 3 } };

const icons = {
  Moving: new H.map.Icon(carMoving, iconOptions),
  MovingN: new H.map.Icon(carMovingNorth, iconOptions),
  MovingE: new H.map.Icon(carMovingEast, iconOptions),
  MovingS: new H.map.Icon(carMovingSouth, iconOptions),
  MovingW: new H.map.Icon(carMovingWest, iconOptions),
  Stopped: new H.map.Icon(carStopped, iconOptions),
  Idling: new H.map.Icon(carIdling, iconOptions),
  Unknown: new H.map.Icon(carNoSignal, iconOptions),
};

const label = document.createElement("div");
label.style.padding = "0 4px";
label.style.margin = "5px 0 0 15px";
label.style.fontSize = "12px";
label.style.height = "20px";
label.style.backgroundColor = "white";
label.style.border = "1px solid black";

const makeLabel = (v) => {
  const domIcon = new H.map.DomIcon(label, {
    onAttach: function (clonedElement) {
      clonedElement.innerHTML =
        (v.vehicleName || `${v.year} ${v.make} ${v.model}`) + (v.driverName ? ` - ${v.driverName}` : "");
    },
  });

  return new H.map.DomMarker({ lat: v.lastPoint.latitude, lng: v.lastPoint.longitude }, { icon: domIcon });
};

const makeMarker = (v) => {
  const icon = v.status == "Moving" ? icons[`Moving${getIconHeading(v.lastPoint?.heading)}`] : icons[v.status];
  const m = new H.map.Marker({ lat: v.lastPoint.latitude, lng: v.lastPoint.longitude }, { icon: icon });
  m.setData(v);
  return m;
};

const MapVehiclePositions = () => {
  const mapRef = useRef();
  const isPhone = useIsPhone();

  const [bounds, setBounds] = useState();
  const [labelGroup, setLabelGroup] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [showLabels, setShowLabels] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  const showInfoBubble = async (v) => {
    const content = await makeVehicleInfoBubbleHtml(v);
    var bubble = new H.ui.InfoBubble({ lat: v.lastPoint.latitude, lng: v.lastPoint.longitude }, { content });
    mapRef.current.ui.getBubbles().forEach((b) => mapRef.current.ui.removeBubble(b));
    mapRef.current.ui.addBubble(bubble);
  };

  const handleDataLoaded = (data) => {
    mapRef.current.map.getObjects().forEach((o) => mapRef.current.map.removeObject(o));

    var markerGroup = new H.map.Group();
    markerGroup.addEventListener("tap", async (e) => await showInfoBubble(e.target.getData()));
    mapRef.current.map.addObject(markerGroup);

    var _labelGroup = new H.map.Group();
    _labelGroup.setVisibility(showLabels);
    mapRef.current.map.addObject(_labelGroup);

    const _bounds = { minLat: 0, minLng: 0, maxLat: 0, maxLng: 0 };

    data
      .filter((v) => v.lastPoint)
      .filter((v) => (selectedGroup ? selectedGroup.vehicleIds.includes(v.vehicleId) : true))
      .forEach((v) => {
        const { latitude: lat, longitude: lng } = v.lastPoint;
        if (_bounds.minLat === 0 || lat < _bounds.minLat) _bounds.minLat = lat;
        if (_bounds.minLng === 0 || lng < _bounds.minLng) _bounds.minLng = lng;
        if (_bounds.maxLat === 0 || lat > _bounds.maxLat) _bounds.maxLat = lat;
        if (_bounds.maxLng === 0 || lng > _bounds.maxLng) _bounds.maxLng = lng;

        markerGroup.addObject(makeMarker(v));
        _labelGroup.addObject(makeLabel(v));
      });

    setLabelGroup(_labelGroup);
    if (!selectedVehicle) setBounds(_bounds);
  };

  const { data, error, isFetching, isError } = useQuery(
    ["vehicles"],
    () =>
      api
        .getVehicles()
        .then((data) =>
          data.filter((v) => v.lastPoint && v.lastPoint.latitude != 0).map((v) => ({ ...v, ...getStatus(v) }))
        ),
    {
      refetchInterval: 60 * 1000, // 60 seconds
    }
  );

  const { data: vehicleGroups } = useQuery(["vehicleGroups"], api.getVehicleGroups);

  useEffect(() => {
    if (data && mapRef.current) {
      handleDataLoaded(data);
    }
  }, [data, selectedVehicle, selectedGroup, mapRef.current]);

  const handleSelectVehicle = (v) => {
    setSelectedVehicle(v);
    setSelectedGroup();
    setBounds({
      minLat: v.lastPoint.latitude - 0.05,
      minLng: v.lastPoint.longitude - 0.05,
      maxLat: v.lastPoint.latitude + 0.05,
      maxLng: v.lastPoint.longitude + 0.05,
    });
    showInfoBubble(v);
    if (isPhone) setShowFilters(false);
  };

  const handleSelectGroup = (group) => {
    setSelectedGroup(group);
    setSelectedVehicle();
    if (isPhone) setShowFilters(false);
  };

  const handleToggleLabels = () => {
    labelGroup.setVisibility(!showLabels);
    setShowLabels(!showLabels);
  };

  return (
    <>
      {isFetching && <LoadingOverlay visible />}
      {isError && <ErrorAlert message={error.message} />}
      <HereMap bounds={bounds} ref={mapRef}>
        <div
          style={{
            position: "absolute",
            left: "20px",
            top: "20px",
            zIndex: "2",
          }}
        >
          <Group mb="xs">
            <ActionIcon variant="filled" color="green" onClick={() => setShowFilters((x) => !x)}>
              <MdFilterAlt />
            </ActionIcon>
            <ActionIcon variant={showLabels ? "filled" : "outline"} color="green" onClick={handleToggleLabels}>
              <FaTags />
            </ActionIcon>
          </Group>
          {showFilters && (
            <VehicleFilterPanel
              vehicles={data}
              vehicleGroups={vehicleGroups}
              onSelectVehicle={handleSelectVehicle}
              onSelectGroup={handleSelectGroup}
              onClearFilter={() => handleSelectGroup(undefined)}
            />
          )}
        </div>
      </HereMap>
    </>
  );
};

export default MapVehiclePositions;
